import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import { Box, Flex } from "reflexbox"

import Layout from "~/components/common/Layout"

import { Heading, Text, Ul } from "~/components/common/Typography"
import HeroVideo from "~/components/page/HeroVideo"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import Button from "~/components/common/Button"
import GraphicWithText from "~/components/page/GraphicWithText"
import {
  getBackLink,
  getHeaderTitles,
  getNextLink,
  getNextTitle,
} from "~/services/pages"
import withProgress from "~/services/withProgress"
import Copyright from "~/components/common/Copyright"
import Source from "~/components/common/Source"

const Table = () => {
  const textProps = {
    fontSize: ["16px", "16px", "18px"],
    lineHeight: ["19px", "19px", "21px"],
  }
  const boldCss = css`
    font-family: "AdineuePROBoldWeb";
  `
  return (
    <table
      cellSpacing="0"
      css={css`
        border: 1px solid black;
        table,
        th,
        td {
          border: 1px solid black;
          margin: 0;
          padding: 10px;
          padding-left: 20px;
          text-align: left;
          border-spacing: 0; /* Removes the cell spacing via CSS */
          border-collapse: collapse;
          text-transform: uppercase;
        }
        th {
          font-family: "AdineuePROBoldWeb";
        }
        td {
          font-family: "AdineuePRORegularWeb;
        }
      `}
    >
      <thead>
        <tr>
          <th>
            <Heading
              fontSize={["16px", "16px", "24px"]}
              lineHeight={["19px", "19px", "29px"]}
            >
              FORMAL TIME
            </Heading>
          </th>
          <th>
            <Heading
              fontSize={["16px", "16px", "24px"]}
              lineHeight={["19px", "19px", "29px"]}
            >
              INFORMAL TIME
            </Heading>
          </th>
        </tr>
      </thead>
      <tr>
        <td valign="top">
          <Text {...textProps}>
            <span css={boldCss}>In school:</span> being in class, attending an
            assembly
          </Text>
        </td>
        <td valign="top">
          <Text {...textProps}>
            <span css={boldCss}>In schools:</span> time in the hallway during
            passing periods, lunch, and recess
          </Text>
        </td>
      </tr>
      <tr>
        <td valign="top">
          <Text {...textProps}>
            <span css={boldCss}>In sports:</span> running an organized drill,
            playing in a game
          </Text>
        </td>
        <td valign="top">
          <Text {...textProps}>
            <span css={boldCss}>In sports:</span> getting ready in the locker
            room, walking to practice, the bus ride to an away game
          </Text>
        </td>
      </tr>
    </table>
  )
}

//TODO extract to common component if you have time before the deadline
const Table2 = () => {
  const textProps = {
    fontSize: ["16px", "16px", "18px"],
    lineHeight: ["19px", "19px", "21px"],
  }
  const boldCss = css`
    font-family: "AdineuePROBoldWeb";
  `
  return (
    <table
      cellSpacing="0"
      css={css`
        border: 1px solid black;
        width: 100%;
        
        table,
        th,
        td {
          border: 1px solid black;
          margin: 0;
          padding: 10px;
          padding-left: 20px;
          text-align: left;
          border-spacing: 0; /* Removes the cell spacing via CSS */
          border-collapse: collapse;
          text-transform: uppercase;
          width: 100%;
        }
        tr{
          width: 100%;
        }
        th {
          font-family: "AdineuePROBoldWeb";
        }
        td {
          font-family: "AdineuePRORegularWeb;
        }
      `}
    >
      <thead>
        <tr>
          <th>
            <Heading
              fontSize={["16px", "16px", "24px"]}
              lineHeight={["19px", "19px", "29px"]}
            >
              SPORTS
            </Heading>
          </th>
        </tr>
      </thead>
      <tr>
        <td valign="top">
          <Text {...textProps}>Before and after practice</Text>
        </td>
      </tr>
      <tr>
        <td valign="top">
          <Text {...textProps}>Bus rides to games</Text>
        </td>
      </tr>
      <tr>
        <td valign="top">
          <Text {...textProps}>Going to and from practice</Text>
        </td>
      </tr>
      <tr>
        <td valign="top">
          <Text {...textProps}>Breaks between drills / water breaks</Text>
        </td>
      </tr>
      <tr>
        <td valign="top">
          <Text {...textProps}>Locker rooms</Text>
        </td>
      </tr>
    </table>
  )
}

const Page = ({ myPage = 1, mySubpage = 1 }) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(name: { eq: "2.2HERO" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      camp: file(name: { eq: "2.2a-ADIDAS-cabins" }) {
        childImageSharp {
          fluid(maxWidth: 993, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic1: file(name: { eq: "2.2b-ADIDAS-jerseys" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic2: file(name: { eq: "2.2c-ADIDAS-lunch" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic3: file(name: { eq: "2.2d-ADIDAS-conversation" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      bus: file(name: { eq: "2.2e-ADIDAS-bus" }) {
        childImageSharp {
          fluid(maxHeight: 6800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  const [quizClicked, setQuizClicked] = useState(null)
  const { hero, camp, graphic1, graphic2, graphic3, bus } = data

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="Relationship building is always happening. It is not something that you can turn on and off at certain times."
        image={hero}
      />

      <Infobox uppercase>
        In sports, there are two crucial environments where girls build
        relationships: formal time and informal time. Formal time is planned,
        structured time which can include activities, games, and drills.
        Informal time is our down time, our in-between time.<Source>16</Source>
      </Infobox>

      <InfoboxDetailed headerText={"FORMAL VS. INFORMAL TIME"} py={6}>
        <Flex
          mt={[6, 7]}
          maxWidth={"800px"}
          width={"100%"}
          mx={"auto"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Table />
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"POP QUIZ"}
        headerBackground={"transparent"}
        bg={"seafoam"}
        contentMaxWidth={"desktop"}
        py={5}
      >
        <Box bg={"white-panel"} mt="5" px={[5, 5, 6]} py={[6, 6]}>
          <Heading
            fontSize={["20px", "32px"]}
            lineHeight={["30px", "36px"]}
            fontFamily={"AdineuePROBoldWeb"}
            css={css`
              text-transform: none;
            `}
          >
            Where do coaches tend to focus more energy? Formal or informal time?
          </Heading>
          <Flex
            flexDirection={["column", "row"]}
            justifyContent={"center"}
            alignItems={"center"}
            mt={6}
            px={5}
          >
            <Box width={["100%", "auto"]}>
              <Button
                mr={[0, 6]}
                height={"64px"}
                width={["100%", "auto"]}
                onClick={() => setQuizClicked(1)}
              >
                FORMAL TIME
              </Button>
            </Box>
            <Box width={["100%", "auto"]}>
              <Button
                mt={[6, 0]}
                height={"64px"}
                width={["100%", "auto"]}
                onClick={() => setQuizClicked(2)}
              >
                INFORMAL TIME
              </Button>
            </Box>
          </Flex>
          <Flex
            flexDirection={"column"}
            mt={6}
            maxWidth={"mediumContent"}
            mx={"auto"}
          >
            {!(quizClicked === null) && (
              <Box>
                <Heading
                  color={quizClicked === 1 ? "seafoam" : "red"}
                  lineHeight={["24px"]}
                  fontSize={["29px"]}
                >
                  {quizClicked === 1 ? "CORRECT!" : "INCORRECT"}
                </Heading>
                <Text lineHeight={"27px"}>
                  Most coaches spend more time thinking about practice, drills,
                  and general organization; but when it comes to building
                  relationships, your informal time is just as important, if not
                  more important, to develop these close bonds. Let’s explore
                  why.
                </Text>
              </Box>
            )}
          </Flex>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={
          "Let’s look at informal time through the lens of summer camp."
        }
        contentMaxWidth={"desktop"}
        py={5}
      >
        <Text
          maxWidth={"mediumContent"}
          mx={"auto"}
          my={6}
          lineHeight={["24px", "27px"]}
        >
          The American Camp Association conducted a study to find out what makes
          kids want to go back to their respective summer camps year after year.
        </Text>
        <Box maxWidth={"993px"} mx={"auto"} mt={7}>
          <Img
            fluid={camp.childImageSharp.fluid}
            alt={"Camp graphic"}
            loading={"eager"}
          />
        </Box>
        <Flex
          maxWidth={"desktop"}
          mx={"auto"}
          mt={0}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Box maxWidth={"mediumContent"} width={"100%"}>
            <Text my={6} lineHeight={["24px", "27px"]}>
              As a part of the study, researchers focused on a sleepaway camp,
              taking note of how many kids decided to come back for the second
              year. They found that a deciding factor for why kids chose to come
              back was whether or not they enjoyed{" "}
              <span
                css={css`
                  font-family: "AdineuePROBoldWeb";
                `}
              >
                cabin time
              </span>
              . They found it was the time between activities, and not the
              activities themselves, that made the biggest difference. According
              to the study, most of the kids who decided not to come back tied
              their decision back to their experience during cabin time.
            </Text>
            <Heading
              as="h2"
              fontSize={["18px", "32px"]}
              lineHeight={["27px", "48px"]}
            >
              Cabin time is an example of what we call{" "}
              <span
                css={css`
                  text-decoration: underline;
                `}
              >
                informal time
              </span>
              . Time when kids are hanging out in between activities. Time when
              awesome things can happen, but negative things can take place,
              too.
            </Heading>
          </Box>
          <Flex
            flexDirection={["column", "row"]}
            mx={"auto"}
            maxWidth={"desktop"}
          >
            <GraphicWithText image={graphic1} mr={[0, 7]}>
              Having friends to walk to the cabin with
            </GraphicWithText>
            <GraphicWithText image={graphic2} mr={[0, 7]}>
              Having people to sit with at meal times
            </GraphicWithText>
            <GraphicWithText image={graphic3}>
              Being included in conversations in the cabin
            </GraphicWithText>
          </Flex>
          <Box width={"100%"}>
            <Text mb={6} lineHeight={["24px", "27px"]}>
              Often, these are moments where some counselors decide to take a
              break or do their own things. But great counselors know that
              informal time is when relationships are built and where adults can
              help young people have positive experiences.
            </Text>
          </Box>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"So what does informal time mean in the context of sports?"}
        headerMaxWidth={"desktop"}
        contentMaxWidth={"desktop"}
        bg={"seafoam"}
        headerBackground={"transparent"}
      >
        <Box bg={"white-panel"} p={["5", "7"]} mt={5}>
          <Flex
            maxWidth={"800px"}
            width={"100%"}
            mx={"auto"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Table2 />
          </Flex>
          <Box maxWidth={"mediumContent"} mx={"auto"} mt={6} px={3}>
            <Text
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "29px"]}
              my={0}
              color={"black"}
            >
              As a coach, you need to be aware of and intentional about what
              happens during informal time in your practices and games and
              leverage it to build relationships and keep girls happy and safe.
            </Text>
          </Box>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"Making time at the right time"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
      >
        <Flex flexDirection={["column", "row"]} mt={6}>
          <Flex width={["100%", "50%"]} height={"100%"}>
            <Box width="100%" maxHeight={"680px"} mx={"auto"}>
              <Img
                fluid={bus.childImageSharp.fluid}
                margin={"0 auto"}
                loading={"eager"}
                imgStyle={{ objectFit: "contain" }}
                css={css`
                  max-height: 100%;
                `}
              ></Img>
            </Box>
          </Flex>
          <Box
            width={["100%", "50%"]}
            height={"fit-content"}
            px={6}
            mt={[0, 5]}
            textAlign={"left"}
          >
            <Text mt={0} fontFamily={"AdineuePROBoldWeb"}>
              1. When Coach Caitlin gets on the bus with her players, she goes
              to the middle and sits next to a kid who doesn’t have a seatmate.
            </Text>
            <Text fontFamily={"AdineuePROBoldWeb"}>
              2. The other players will come sit next to them to talk to Coach
              Caitlin.
            </Text>
            <Text fontFamily={"AdineuePROBoldWeb"}>
              3. Coach Caitlin can gradually bring the less involved players
              into the conversation through casual chats.
            </Text>
            <Text>
              It doesn’t take any formal agenda to make a positive impact on
              players. Paying attention to informal time makes it easier to do
              the following:
            </Text>
            <Ul lineHeight={[2, 4]} mb={6}>
              <li>Focus on a smaller group of players</li>
              <li>Invest in quality one-on-one time</li>
              <li>Get to know them beyond the scope of the sport</li>
              <li>Get a sense of how they’re doing each day</li>
            </Ul>
            <Text>
              Now that you know when to make an impact, let’s talk about how you
              can develop strong relationships between yourself as a coach and
              your players.
            </Text>
          </Box>
        </Flex>
      </InfoboxDetailed>
      <Copyright mt={6} />
    </Layout>
  )
}

export default withProgress(Page, 1, 1)
